import React from "react"
import { Link } from "gatsby"
import { SEO, Header, Footer, DCXButton, Steps } from "../components"
import {
  HowDoesItWork1Image,
  HowDoesItWork2Image,
  HowDoesItWork3Image,
} from "../assets"
import { urlWithSession } from "../utils"
import { getStandardAnimationProps } from "../constants"

export default function HowDoesItWork() {
  const standardAnimationProps = getStandardAnimationProps()
  return (
    <>
      <SEO
        url="https://dercocenterx.cl/how-does-it-works"
        title="¿Cómo funciona? | DercoCenter X"
        description="La nueva forma de comprar tu auto. Explora, crea y compra en un solo lugar"
      />
      <Header />
      <div className="how-does-it-work-page">
        <div className="page-title" {...standardAnimationProps}>
          La nueva forma de comprar tu auto
        </div>
        <div className="page-desc" {...standardAnimationProps}>
          Explora, crea y compra en un solo lugar
        </div>
        <div className="summary-images">
          {FRAMES.map((frame, idx) => (
            <div
              className={`frame ${idx === 2 ? "last" : ""}`}
              key={frame.title}
              {...getStandardAnimationProps(idx)}
            >
              <img className="frame-image" src={frame.imageSrc} alt="" />
              <div className="frame-title">{frame.title}</div>
              <div className="frame-desc">{frame.desc}</div>
            </div>
          ))}
        </div>
        <Link to={urlWithSession("/home")} {...standardAnimationProps}>
          <DCXButton type="primary" text="VER AUTOS" />
        </Link>
        <Steps title="¿Cómo funciona?" />
        <Link to={urlWithSession("/")} {...getStandardAnimationProps(0, 1000)}>
          <DCXButton type="primary" text="VER AUTOS" />
        </Link>
      </div>
      <Footer />
    </>
  )
}

const FRAMES = [
  {
    imageSrc: HowDoesItWork1Image,
    title: "Elige tu cuota mensual",
    desc: "Puedes pagar al contado o simular tu crédito",
  },
  {
    imageSrc: HowDoesItWork2Image,
    title: "Tasa tu auto actual en línea",
    desc: "Recibe una oferta de tasación en sólo minutos",
  },
  {
    imageSrc: HowDoesItWork3Image,
    title: "Personaliza tu próximo auto",
    desc: "Y te lo llevamos a tu casa, si no te gusta ¡Lo devuelves!",
  },
]
